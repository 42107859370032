<template>
  <section class="w-full mt-60px mx-auto px-inst22 md:(px-0 w-680px mx-auto) lg:(px-0 w-1060px mt-80px mx-auto)">
    <div class="text-inst28 text-center leading-inst font-600 md:(text-inst36) lg:(text-inst46 text-center leading-inst font-600)">
      {{ $t('product.second.t1') }}
    </div>
    <div class="mt-10px text-inst16 text-center leading-inst md:(text-inst17) lg:(mt-10px text-inst22 text-center leading-inst)">
      {{ $t('product.second.t2') }}
    </div>
    <div class="mt-30px flex flex-col lg:(mt-40px flex flex-row justify-between)">
      <div
        v-for="(item, index) in list"
        :key="item.title1"
        class="flex flex-col items-center text-center lg:(flex flex-col items-center text-center)"
        :class="[index === list.length - 1 ? '' : 'mb-30px lg:(mb-0)']"
      >
        <div class="w-full lg:(w-335px h-350px)">
          <img :src="item.icon" alt="" class="w-full h-full">
        </div>
        <div class="max-w-291px mt-10px text-inst20 leading-inst font-600 text-inst000 md:(max-w-370px text-inst17) lg:(max-w-310px mt-20px text-inst20 leading-inst font-600 text-inst000)">{{ $t(item.title1) }}</div>
        <div class="max-w-291px mt-10px text-inst16 leading-inst font-400 text-inst888 md:(max-w-370px text-inst15) lg:(max-w-310px mt-10px text-inst17 leading-inst font-400 text-inst888)">{{ $t(item.title2) }}</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          icon: require('@/assets/images/inst-safe/1.jpg'),
          title1: 'product.second.list.t1',
          title2: 'product.second.list.t2'
        },
        {
          icon: require('@/assets/images/inst-safe/2.jpg'),
          title1: 'product.second.list.t3',
          title2: 'product.second.list.t4'
        },
        {
          icon: require('@/assets/images/inst-safe/3.jpg'),
          title1: 'product.second.list.t5',
          title2: 'product.second.list.t6'
        }
      ]
    }
  }
}
</script>
