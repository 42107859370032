<template>
  <section class="w-full px-inst22 mt-60px mx-auto lg:(px-0 w-1060px mt-80px mx-auto)">
    <div class="text-inst22 leading-inst text-center text-inst000 font-600 md:(px-0 w-680px mx-auto text-inst24) lg:(w-full text-inst32 leading-inst text-center text-inst000 font-600)">{{ $t('product.third.t1') }}</div>
    <!--  -->
    <div class="mt-30px md:(w-720px mx-auto) lg:(w-full mt-40px)">
      <!--  -->
      <div class="flex flex-col justify-center items-center md:(flex flex-row justify-around items-start) lg:(flex flex-row justify-around items-center)">
        <div
          v-for="(item, index) in topList"
          :key="item.title1"
          :class="[
          index === topList.length - 1 ? '' : 'mb-30px md:(mb-0) lg:(mb-0)',
          index === 1 ? 'lg:(mx-50px)' : ''
          ]"
          class="px-22px md:(px-0 w-210px) lg:(px-0 w-320px)"
        >
          <div class="flex flex-col items-center md:(flex flex-row items-start) lg:(flex flex-row items-start)">
            <div>
              <inst-icon :icon-class="item.icon" class="w-50px h-50px md:(w-32px h-32px) lg:(w-60px h-60px)" />
            </div>
            <div class="mt-10px text-center md:(text-left mt-0 w-148px ml-10px) lg:(text-left mt-0 w-245px ml-15px)">
              <div class="text-inst16 leading-inst text-inst000 font-600 lg:(text-inst17 leading-inst text-inst000 font-600)">{{ $t(item.title1) }}</div>
              <div class="mt-2px text-inst16 leading-inst text-inst888 font-400 lg:(mt-4px text-inst17 leading-inst text-inst888 font-400)">{{ $t(item.title2) }}</div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="flex flex-col justify-center items-center my-20px md:(my-20px flex flex-row justify-around items-start) lg:(my-40px flex flex-row justify-around items-start)">
        <div
          v-for="(item, index) in middleList"
          :key="item.title1"
          :class="[index === topList.length - 1 ? '' : 'mb-30px md:(mb-0) lg:(mb-0)', index === 1 ? 'lg:(mx-50px)' : '']"
          class="px-22px md:(px-0 w-210px) lg:(px-0 w-320px)"
        >
          <div class="flex flex-col items-center md:(flex flex-row items-start) lg:(flex flex-row items-start)">
            <div>
              <inst-icon :icon-class="item.icon" class="w-50px h-50px md:(w-32px h-32px) lg:(w-60px h-60px)" />
            </div>
            <div class="mt-10px text-center  md:(w-148px mt-0 text-left ml-10px) lg:(w-245px mt-0 text-left ml-15px)">
              <div class="text-inst16 leading-inst text-inst000 font-600 lg:(text-inst17 leading-inst text-inst000 font-600)">{{ $t(item.title1) }}</div>
              <div class="mt-2px text-inst16 leading-inst text-inst888 font-400 lg:(mt-4px text-inst17 leading-inst text-inst888 font-400)">{{ $t(item.title2) }}</div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="flex flex-col justify-center items-center  md:(flex flex-row justify-around items-start) lg:(mt-40px flex flex-row justify-around items-start)">
        <div
          v-for="(item, index) in bottomList"
          :key="item.title1"
          :class="[index === topList.length - 1 ? '' : 'mb-30px md:(mb-0) lg:(mb-0)', index === 1 ? 'lg:(mx-50px)' : '']"
          class="px-22px md:(px-0 w-210px) lg:(px-0 w-320px)"
        >
          <div class="flex flex-col items-center md:(flex flex-row items-start) lg:(flex flex-row items-start)">
            <div>
              <inst-icon :icon-class="item.icon" class="w-50px h-50px md:(w-32px h-32px) lg:(w-60px h-60px)" />
            </div>
            <div class="mt-10px text-center  md:(w-148px mt-0 text-left ml-10px) lg:(w-245px mt-0 text-left ml-15px)">
              <div class="text-inst16 leading-inst text-inst000 font-600 lg:(text-inst17 leading-inst text-inst000 font-600)">{{ $t(item.title1) }}</div>
              <div class="mt-2px text-inst16 leading-inst text-inst888 font-400 lg:(mt-4px text-inst17 leading-inst text-inst888 font-400)">{{ $t(item.title2) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 十重安全保护 -->
    <div class="mt-60px md:(mt-30px) lg:(mt-40px)">
      <!-- 标题 -->
      <div class="flex justify-center items-center lg:(flex justify-center items-center)">
        <div class="h-1px flex-1 bg-inst000 opacity-10 lg:(h-1px w-400px bg-inst000 opacity-10)"></div>
        <div class="w-150px mx-23px text-inst20 text-inst000 leading-inst text-center font-600 md:(w-auto text-inst20) lg:(w-auto mx-58px text-inst24 text-inst000 leading-inst font-600)">{{ $t('product.third.t2') }}</div>
        <div class="h-1px flex-1 bg-inst000 opacity-10 lg:(h-1px w-400px bg-inst000 opacity-10)"></div>
      </div>
      <!-- md lg content -->
      <div class="hidden md:(block mt-30px) lg:(block mt-40px)">
        <!-- top -->
        <div class="md:(flex justify-around text-center) lg:(flex justify-around text-center)">
          <div
            v-for="item in tenSafeList.slice(0,5)"
            :key="item.title"
            class="md:(w-120px flex flex-col items-center) lg:(w-132px flex flex-col items-center)"
          >
            <div>
              <inst-icon :icon-class="item.icon" class="md:(w-48px h-48px) lg:(w-62px h-62px)" />
            </div>
            <div class="md:(mt-10px text-inst14 text-inst888 leading-inst font-400) lg:(mt-20px text-inst17 text-inst888 leading-inst font-400)">{{ $t(item.title) }}</div>
          </div>
        </div>
        <!-- bottom -->
        <div class="md:(block mt-20px flex justify-around text-center) lg:(mt-40px flex justify-around text-center)">
          <div
            v-for="item in tenSafeList.slice(5)"
            :key="item.title"
            class="md:(w-120px flex flex-col items-center) lg:(w-132px flex flex-col items-center)"
          >
            <div>
              <inst-icon :icon-class="item.icon" class="md:(w-48px h-48px) lg:(w-62px h-62px)" />
            </div>
            <div class="md:(mt-10px text-inst14 text-inst888 leading-inst font-400) lg:(mt-20px text-inst17 text-inst888 leading-inst font-400)">{{ $t(item.title) }}</div>
          </div>
        </div>
      </div>
      <!-- sm content -->
      <div class="block mt-30px flex flex-col md:(hidden) lg:(hidden)">
        <div
            v-for="(item, index) in tenSafeList"
            :key="item.title"
            class="flex px-78px flex-col items-center"
            :class="index !== tenSafeList.length - 1 ? 'mb-20px' : ''"
          >
            <div>
              <inst-icon :icon-class="item.icon" style="width:50px;height:50px;" />
            </div>
            <div class="h-auto mt-5px text-inst16 text-inst888 text-center leading-inst font-400">{{ $t(item.title) }}</div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      topList: [
        { icon: 'qualities_1', title1: 'product.third.list1.t1', title2: 'product.third.list1.t2' },
        { icon: 'qualities_2', title1: 'product.third.list1.t3', title2: 'product.third.list1.t4' },
        { icon: 'qualities_3', title1: 'product.third.list1.t5', title2: 'product.third.list1.t6' }
      ],
      middleList: [
        { icon: 'qualities_4', title1: 'product.third.list1.t7', title2: 'product.third.list1.t8' },
        { icon: 'qualities_5', title1: 'product.third.list1.t9', title2: 'product.third.list1.t10' },
        { icon: 'qualities_6', title1: 'product.third.list1.t11', title2: 'product.third.list1.t12' }
      ],
      bottomList: [
        { icon: 'qualities_7', title1: 'product.third.list1.t13', title2: 'product.third.list1.t14' },
        { icon: 'qualities_8', title1: 'product.third.list1.t15', title2: 'product.third.list1.t16' },
        { icon: 'qualities_9', title1: 'product.third.list1.t17', title2: 'product.third.list1.t18' }
      ],
      allDetailList: [],
      tenSafeList: [
        { icon: 'safe-1', title: 'product.third.list2.t1' },
        { icon: 'safe-2', title: 'product.third.list2.t2' },
        { icon: 'safe-3', title: 'product.third.list2.t3' },
        { icon: 'safe-4', title: 'product.third.list2.t4' },
        { icon: 'safe-5', title: 'product.third.list2.t5' },
        { icon: 'safe-6', title: 'product.third.list2.t6' },
        { icon: 'safe-7', title: 'product.third.list2.t7' },
        { icon: 'safe-8', title: 'product.third.list2.t8' },
        { icon: 'safe-9', title: 'product.third.list2.t9' },
        { icon: 'safe-10', title: 'product.third.list2.t10' }
      ]
    }
  },
  created () {
    this.allDetailList = [...this.topList, ...this.middleList, ...this.bottomList]
  }
}
</script>
