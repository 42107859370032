<template>
  <div class="product">
    <inst-banner />
    <inst-safe />
    <inst-more />
    <inst-detail />
    <inst-authorized />
  </div>
</template>

<script>
import Banner from './components/banner.vue'
import InstSafe from './components/product-safe.vue'
import InstMore from './components/product-more.vue'
import InstDetail from './components/product-detail.vue'
import InstAuthorized from './components/product-authorized.vue'

export default {
  components: {
    'inst-banner': Banner,
    'inst-safe': InstSafe,
    'inst-more': InstMore,
    'inst-detail': InstDetail,
    'inst-authorized': InstAuthorized
  }
}
</script>
