<template>
  <section class="w-full px-inst22 mt-60px mx-auto lg:(px-0 w-1060px mx-auto mt-80px)">
    <div class="text-inst28 text-center leading-inst text-inst000 font-600 lg:(text-inst40 text-center leading-inst text-inst000 font-600)">{{ $t('product.fivth.t1') }}</div>
    <!-- content -->
    <div class="mt-30px flex flex-col lg:(mt-40px flex flex-row justify-between)">
      <div
        v-for="(item, index) in list"
        :key="item.title"
        class="w-full flex flex-col items-center text-center lg:(w-200px flex flex-col items-center)"
        :class="index === list.length -1 ? '' : 'mb-30px lg:(mb-0)'"
      >
        <div class="inst-border flex justify-center w-full h-70px rounded-8px lg:(w-full h-70px rounded-8px)">
          <img :src="item.icon" alt="" class="h-full lg:(w-full)">
        </div>
        <div class="mt-16px text-inst17 leading-inst font-400 lg:(mt-20px text-inst17 leading-inst font-400)">{{ $t(item.title) }}</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      list: [
        { icon: require('@/assets/images/product-authorized/1.png'), title: 'product.fivth.list.t1' },
        { icon: require('@/assets/images/product-authorized/2.png'), title: 'product.fivth.list.t2' },
        { icon: require('@/assets/images/product-authorized/3.png'), title: 'product.fivth.list.t3' },
        { icon: require('@/assets/images/product-authorized/4.png'), title: 'product.fivth.list.t4' },
        { icon: require('@/assets/images/product-authorized/5.png'), title: 'product.fivth.list.t5' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.inst-border {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
